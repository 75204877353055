<template>
  <div>
    <a-card :title="$t('临期预警')">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" :placeholder="$t('货品编号, 名称')" allowClear @search="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id"
              >{{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>

      <a-row>
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ x: 1060 }"
          @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? $t("激活") : $t("冻结") }}</a-tag>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { warehouseOption } from "@/api/option";
import { batchList } from "@/api/material";

export default {
  components: {},
  data() {
    return {
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          fixed: "left",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: this.$t("编号"),
          dataIndex: "number",
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t("库存数量"),
          dataIndex: "remain_quantity",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("生产日期"),
          dataIndex: "production_date",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("到期日期"),
          dataIndex: "expiration_date",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("货品编号"),
          dataIndex: "material_number",
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t("货品名称"),
          dataIndex: "material_name",
          sorter: true,
          width: 240,
          ellipsis: true,
        },
        {
          title: this.$t("仓库"),
          dataIndex: "warehouse_name",
          width: 120,
          ellipsis: true,
        },
      ],
      searchForm: { search: "", page: 1, page_size: 16, has_stock: true, is_expiration_warning: true },
      pagination: { current: 1, total: 0, pageSize: 16 },
      warehouseItems: [],
      loading: false,
      items: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.searchForm.warehouse = this.currentWarehouse;
      });
    },
    list() {
      this.loading = true;
      batchList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
